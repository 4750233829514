<template>
    <div>
        <base-material-card :icon="resource.icon" :title="title">
            <div
                class="text-right"
                style="position: absolute; right: 10px; z-index: 999"
            >
                <date-range-picker
                    ref="picker"
                    :opens="'left'"
                    :timePicker="false"
                    :showWeekNumbers="true"
                    :showDropdowns="true"
                    :autoApply="true"
                    v-model="dateRange"
                    @update="(val) => update(val)"
                    :locale-data="{
                        firstDay: 1,
                    }"
                >
                    <template v-slot:input="picker" style="min-width: 350px">
                        <label
                            v-if="!picker.startDate || !picker.startDate"
                            class="v-label theme--light"
                        >
                            Date Range
                        </label>
                        <template v-else>
                            {{ picker.startDate | moment("timezone", tz, "L") }}
                            -
                            {{ picker.endDate | moment("timezone", tz, "L") }}
                        </template>
                    </template>
                </date-range-picker>
            </div>
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="transparent">
            </v-tabs-items>
            <v-row>
                <v-col class="text-center font-weight-bold">
                    <span>
                        Total: {{ orderCount }} orders,
                        {{ totalOrderedQuantity }} Unit sold,
                        {{ totalOrderedAmount }} $.
                    </span>
                    <br />
                    <span>Latest updated at: {{ latestUpdatedAt }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <va-list
                        resource="Order"
                        ref="list"
                        class="order-list border-e-lg"
                        :filters="filters"
                        :filter.sync="filter"
                        :sort-by.sync="sort.by"
                        :sort-desc.sync="sort.desc"
                        disable-create
                        disable-export
                    >
                        <va-data-table
                            :fields="fields"
                            disable-actions
                            disable-select
                            show-expand
                        >
                            <template v-slot:expanded-item="{ item }">
                                <json-viewer
                                    :value="item"
                                    :expand-depth="5"
                                    copyable
                                    sort
                                ></json-viewer>
                            </template>
                            <template v-slot:[`field.PurchaseDate`]="{ value }">
                                {{
                                    value | moment("timezone", sourceTz, "LLL")
                                }}
                            </template>
                        </va-data-table>
                    </va-list>
                </v-col>
                <v-col>
                    <va-list
                        resource="OrderForecastItem"
                        ref="item_list"
                        class="order-forecast-item-list"
                        :filters="itemFilters"
                        :filter.sync="itemFilter"
                        :sort-by.sync="itemSort.by"
                        :sort-desc.sync="itemSort.desc"
                        :items-per-page="10"
                        disable-create
                        disable-export
                        :include="[
                            'cogCatalogItem.product',
                            'cogCatalogItem.pic',
                        ]"
                    >
                        <va-data-table
                            :fields="itemFields"
                            disable-actions
                            disable-select
                            show-expand
                        >
                            <template
                                v-slot:[`field.ImageAttribute`]="{
                                    value,
                                    item,
                                }"
                            >
                                <img
                                    width="75"
                                    v-if="value && value.SmallImage"
                                    :alt="value.SmallImage.URL"
                                    :src="getImage(item, value.SmallImage.URL)"
                                />
                            </template>
                        </va-data-table>
                    </va-list>
                </v-col>
            </v-row>
        </base-material-card>
    </div>
</template>

<script>
import { sourceTz, tz } from "@/constants/Time";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment-timezone";
import OrderStatuses from "@/constants/OrderStatuses";

export default {
    props: {
        resource: {
            type: Object,
        },
        mwsConfigurationId: {
            type: Number,
        },
        title: {
            type: String,
        },
        perPage: {
            type: Number,
            default: 15,
        },
    },
    components: { DateRangePicker },
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
                this.itemFilter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;

                const itemFilter = this.itemFilters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                itemFilter.attributes.choices = [...newVal];
            },
        },
    },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            dateFormat: this.$admin.options.dateFormat,
            selectedTab: 0,
            dateRange: {},
            filter: {
                date_between: `${moment()
                    .startOf("month")
                    .format("YYYY-MM-DD 00:00:00")},${moment()
                    .endOf("month")
                    .format("YYYY-MM-DD 23:59:59")}`,
                mws_configuration_id: this.mwsConfigurationId
                    ? [this.mwsConfigurationId]
                    : [],
            },
            sort: {
                by: ["PurchaseDate"],
                desc: [true],
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "OrderStatus",
                    type: "select",
                    attributes: {
                        choices: OrderStatuses,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "SalesChannel",
                    type: "select",
                    attributes: {
                        choices: [],
                        multiple: true,
                    },
                    alwaysOn: true,
                },
            ],
            fields: [
                { source: "AmazonOrderId", sortable: true },
                { source: "PurchaseDate", sortable: true },
                { source: "OrderStatus", sortable: true },
                { source: "SalesChannel", sortable: true },
                { source: "amount", sortable: true },
            ],
            itemSort: {
                by: ["QuantityOrdered"],
                desc: [true],
            },
            itemFilter: {
                date_between: `${moment()
                    .startOf("month")
                    .format("YYYY-MM-DD 00:00:00")},${moment()
                    .endOf("month")
                    .format("YYYY-MM-DD 23:59:59")}`,
                mws_configuration_id: this.mwsConfigurationId
                    ? [this.mwsConfigurationId]
                    : [],
            },
            itemFilters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    label: "PIC",
                    source: "pic_id",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: false,
                        reference: "users",
                    },
                    alwaysOn: true,
                },
            ],
            itemFields: [
                {
                    label: "Image",
                    source: "ImageAttribute",
                    align: "start",
                    sortable: false,
                },
                {
                    label: "ASIN",
                    source: "ASIN",
                    align: "start",
                    sortable: true,
                },
                {
                    label: "SKU",
                    source: "SellerSKU",
                    align: "start",
                    sortable: true,
                },
                {
                    label: "PIC",
                    source: "pic",
                    align: "start",
                    sortable: false,
                },
                {
                    label: "Unit Sold",
                    source: "QuantityOrdered",
                    align: "right",
                    sortable: true,
                },
            ],
            orderCount: 0,
            totalOrderedQuantity: 0,
            totalOrderedAmount: 0,
            latestUpdatedAt: "-",
        };
    },
    beforeMount() {
        const { filter } = this.$route.query;
        if (!filter) {
            this.dateRange.startDate = moment().tz(tz).startOf("month");
            this.dateRange.endDate = moment().tz(tz).endOf("month");
            return;
        }
        const currentFilter = JSON.parse(filter);
        if (!currentFilter["date_between"]) {
            return;
        }
        const [start, end] = currentFilter["date_between"].split(",");
        this.dateRange.startDate = moment(start);
        this.dateRange.endDate = moment(end);
    },
    mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            () => {
                this.getSummary(this.filter);
            }
        );
        this.$watch(
            () => {
                return this.$refs.item_list.listState.items;
            },
            () => {
                this.getSummary(this.itemFilter);
            }
        );
    },
    methods: {
        getImage(item, defaultImage) {
            if (!item.image_file) {
                return defaultImage;
            }
            return item.image_file;
        },
        update(value) {
            const start = moment(value.startDate);
            const end = moment(value.endDate);
            this.filter = {
                ...this.filter,
                date_between: `${start
                    .clone()
                    .tz(tz)
                    .format("YYYY-MM-DD 00:00:00")},${end
                    .clone()
                    .tz(tz)
                    .format("YYYY-MM-DD 23:59:59")}`,
            };
            this.itemFilter = {
                ...this.itemFilter,
                date_between: `${start
                    .clone()
                    .tz(tz)
                    .format("YYYY-MM-DD 00:00:00")},${end
                    .clone()
                    .tz(tz)
                    .format("YYYY-MM-DD 23:59:59")}`,
            };
        },
        async getSummary(filter) {
            const { data } = await this.$store.dispatch(
                "OrderForecastSummary/getList",
                {
                    filter: {
                        ...filter,
                    },
                }
            );

            const {
                quantity_ordered,
                order_count,
                amount_ordered,
                latest_updated_at,
                sale_channels,
            } = data;

            this.orderCount = order_count;
            this.totalOrderedQuantity = quantity_ordered;
            this.totalOrderedAmount = amount_ordered;
            this.latestUpdatedAt = latest_updated_at;
            this.filters.find(
                (filter) => filter.source === "SalesChannel"
            ).attributes.choices = sale_channels.map((sale_channel) => ({
                text: sale_channel,
                value: sale_channel,
            }));
        },
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
            this.$refs.list.fetchData();
            this.itemFilter["mws_configuration_id"] = id ? id : "";
            this.$refs.item_list.fetchData();
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
    },
};
</script>
<style lang="sass">
.order-list, .order-forecast-item-list
    > header
      > div
            > form
                > div
                    > div:nth-child(2)
                        display: none !important
.order-list
  border-right: 1px solid black
</style>
