var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.mwsConfigurationOptions),function(tab){return _c('v-tab',{key:tab.value,staticClass:"mr-3",on:{"click":function($event){return _vm.onClickTab(tab.value)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-amazon")]),_vm._v(" "+_vm._s(tab.text)+" ")],1)}),1),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}),_c('va-list',{ref:"list",staticClass:"sale-inventory-list",attrs:{"filters":_vm.filters,"disable-create":"","disable-export":"","filter":_vm.filter,"sort-by":_vm.sort.by,"sort-desc":_vm.sort.desc,"items-per-page":100,"items-per-page-options":[100, 200, 300, 500]},on:{"update:filter":function($event){_vm.filter=$event},"update:sortBy":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "desc", $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('va-action-button',{attrs:{"label":"Export","icon":"mdi-download","text":"","loading":_vm.isGenerating},on:{"click":_vm.exportAll}}),_c('a',{ref:"fileLinkElement",staticClass:"d-none",attrs:{"href":_vm.fileLink,"download":"sale-inventory.csv"}},[_vm._v("Download exported file.")])]},proxy:true}])},[_c('va-data-table',{ref:"table",staticClass:"sale-inventory-list-main-table",attrs:{"fields":_vm.fields,"disable-edit":"","disable-clone":"","disable-show":"","disable-select":"","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"field.asin",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openAsinReport(item)}}},[_vm._v(_vm._s(value))])]}},{key:"field.sku",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-sku"},[_vm._v(" "+_vm._s(value.includes(",") ? "Multiple" : value)+" ")])]}},{key:"field.fnSku",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-fn-sku"},[_vm._v(" "+_vm._s(value && value.includes(",") ? "Multiple" : value)+" ")])]}},{key:"field.pics",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-pics"},[_vm._v(" "+_vm._s(value ? value.map(function (i) { return i.name; }).join(", ") : "")+" ")])]}},{key:"field.suppliers",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-suppliers"},[_vm._v(" "+_vm._s(value ? value.map(function (i) { return i.name; }).join(", ") : "")+" ")])]}},{key:"field.asin_age",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"field.sum_sale_1_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(item, 'diff_sum_sale_1_day')},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_1_day"))+" ")])]}},{key:"field.sum_sale_3_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(item, 'diff_sum_sale_3_day')},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_3_day"))+" ")])]}},{key:"field.sum_sale_7_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(item, 'diff_sum_sale_7_day')},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_7_day"))+" ")])]}},{key:"field.sum_sale_15_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(item, 'diff_sum_sale_15_day')},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_15_day"))+" ")])]}},{key:"field.sum_sale_30_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(item, 'diff_sum_sale_30_day')},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_30_day"))+" ")])]}},{key:"field.label",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"text-color":"white","color":_vm.getLabelColor(value)}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"field.tags",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.is_low_price)?_c('v-chip',[_vm._v(" "+_vm._s("LowPrice")+" ")]):_vm._e(),(item.is_neg_gp)?_c('v-chip',[_vm._v(" "+_vm._s("NegGP")+" ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(item.children.length)?_c('v-data-table',{staticClass:"sale-inventory-list-item-headers",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.item_headers,"items":item.children},scopedSlots:_vm._u([{key:"item.first",fn:function(ref){return [_c('button',{staticClass:"\n                                v-icon\n                                v-data-table__expand-icon\n                                v-icon--link\n                                mdi mdi-square-small\n                            ",attrs:{"type":"button"}})]}},{key:"item.small_image",fn:function(ref){return undefined}},{key:"item.sku",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-sku"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.fnSku",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-fn-sku"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.pics",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-pics"},[_vm._v(" "+_vm._s(value ? value.map(function (i) { return i.name; }).join(", ") : "")+" ")])]}},{key:"item.suppliers",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"col-suppliers"},[_vm._v(" "+_vm._s(value ? value.map(function (i) { return i.name; }).join(", ") : "")+" ")])]}},{key:"item.sum_sale_1_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(item, 'diff_sum_sale_1_day')},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_1_day"))+" ")])]}},{key:"item.sum_sale_3_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(item, 'diff_sum_sale_3_day')},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_3_day"))+" ")])]}},{key:"item.sum_sale_7_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(item, 'diff_sum_sale_7_day')},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_7_day"))+" ")])]}},{key:"item.sum_sale_15_day",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(
                                    item,
                                    'diff_sum_sale_15_day'
                                )},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_15_day"))+" ")])]}},{key:"item.sum_sale_30_day",fn:function(ref){
                                var value = ref.value;
                                var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),_c('br'),_c('span',{class:_vm.getDiffCssClass(
                                    item,
                                    'diff_sum_sale_30_day'
                                )},[_vm._v(" "+_vm._s(_vm.getDiffLabel(item, "diff_sum_sale_30_day"))+" ")])]}},{key:"item.label",fn:function(ref){
                                var value = ref.value;
return [_c('v-chip',{attrs:{"text-color":"white","color":_vm.getLabelColor(value)}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.tags",fn:function(ref){
                                var item = ref.item;
return [(item.is_low_price)?_c('v-chip',[_vm._v(" "+_vm._s("LowPrice")+" ")]):_vm._e(),(item.is_neg_gp)?_c('v-chip',[_vm._v(" "+_vm._s("NegGP")+" ")]):_vm._e()]}}],null,true)}):_vm._e()]}}],null,true)})],1),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" ASIN: "),(_vm.selected)?_c('a',{attrs:{"target":"_blank","href":'https://amazon.com/dp/' + _vm.selected.asin}},[_vm._v(_vm._s(_vm.selected.asin))]):_vm._e()])],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[(!_vm.report)?_c('v-card-text',[_vm._v(" Loading... "),_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_vm._e(),(_vm.report)?_c('date-range-picker',{ref:"picker",attrs:{"opens":'right',"timePicker":false,"showWeekNumbers":true,"showDropdowns":true,"ranges":_vm.dateRanges,"locale-data":{
                                firstDay: 1,
                            }},on:{"update":function (val) { return _vm.applyAsinReportFilter(val); }},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [(
                                        !picker.startDate ||
                                        !picker.startDate
                                    )?_c('label',{staticClass:"v-label theme--light"},[_vm._v(" Date Range ")]):[_vm._v(" "+_vm._s(_vm._f("moment")(picker.startDate,"timezone", _vm.tz, "L"))+" - "+_vm._s(_vm._f("moment")(picker.endDate,"timezone", _vm.tz, "L"))+" ")]]}}],null,false,2337049453),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}):_vm._e(),(_vm.report)?_c('highcharts',{attrs:{"options":_vm.chartOptions}}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }